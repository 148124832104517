
import { Component, Vue } from 'nuxt-property-decorator'

interface ItemStatistic {
  icon: string;
  caption: string;
  value: string;
}

@Component({})
export default class extends Vue {
  listStatisticArr: ItemStatistic[] = [
    {
      icon: require('~/assets/img/blocks-info/paid-of.svg'),
      caption: '4 200 000+ штрафов',
      value: 'погашено'
    },
    {
      icon: require('~/assets/img/blocks-info/users.svg'),
      caption: '3 300 000+',
      value: 'пользователей'
    },
    {
      icon: require('~/assets/img/blocks-info/saved.svg'),
      caption: '98 700 000+ рублей',
      value: 'сэкономлено благодаря скидке 25%'
    }
  ]
}
