
import { Component, Vue } from 'nuxt-property-decorator'
import AbstractBlock, { Paragraph } from '../AbstractBlock.vue'

@Component({
  name: 'CheckFinesInfo',
  components: { AbstractBlock }
})
export default class extends Vue {
  content: Paragraph[] = [
    {
      type: 'subtitle',
      value: 'Проверка штрафов по госномеру.'
    },
    {
      type: 'text',
      value: 'С помощью нашего сервиса вы сможете проверить штрафы по гос. номеру. За комбинацией букв и цифр скрывается важная информация. Всего в несколько кликов вы сможете узнать все штрафы по авто, фото с автоматических дорожных камер и номер постановления. Для проверки не нужно искать СТС или иной документ. Все, что нужно знать – это гос. номер машины.'
    },
    {
      type: 'subtitle',
      value: 'Что такое гос. номер?'
    },
    {
      type: 'text',
      value: 'Госномер — это регистрационный знак из букв и цифр, который присваивается каждому автомобилю при постановке на учет в ГИБДД. Регистрационный номер вносится в базу данных ГИБДД, а также в СТС и ПТС. Зная госномер машины, вы всего за несколько минут сможете проверить ее на нашем сайте.'
    },
    {
      type: 'subtitle',
      value: 'Зачем нужна проверка по гос. номеру?'
    },
    {
      type: 'text',
      value: 'Проверка авто по госномеру – простой и удобный способ узнать информацию о штрафах от ГИБДД. Предлагаем воспользоваться нашим онлайн-сервисом, чтобы получить данные об имеющихся нарушениях ПДД с фотографиями. Вовремя обнаружив штраф, вы сможете погасить его со скидкой 25% и избежать неприятностей, связанных с просрочкой оплаты.'
    },
    {
      type: 'text',
      value: 'Вы также можете выполнить проверку перед покупкой подержанного автомобиля. Наличие неоплаченных штрафов ─ самая распространенная причина ограничений регистрационных действий. Даже небольшая задолженность перед ГИБДД может стать препятствием для переоформления авто. Вот почему так важно выполнить проверку по госномеру перед покупкой машины. С помощью нашего сервиса вы сможете получить достаточно информации, чтобы избежать проблем и неприятных сюрпризов. '
    },
    {
      type: 'text',
      value: 'Чтобы пробить машину, укажите ее госномер и нажмите «Проверить». Наш сервис проведет проверку и предоставит данные о найденных штрафах. Весь процесс займет не больше пары минут.'
    }
  ]
}
